<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <path
                d="M11 4H13V16L18.5 10.5L19.92 11.92L12 19.84L4.07996 11.92L5.49996 10.5L11 16V4Z"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>
