import type { KBItem } from '~/ts/types/knowledge-base'
import flatTree from '~/helpers/knowledge-base/flatTree'

export default (items: KBItem[], id: KBItem['_id']): KBItem | undefined => {
    if (!id) {
        return
    }

    const flattenTree = flatTree(items)

    for (const index in flattenTree) {
        const item = flattenTree[index]

        if (item._id === id) {
            return item
        }
    }
}
