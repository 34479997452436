<template>
    <div
        class="
            hidden
            tablet:flex
            items-center
            py-2
            px-4
            leading-[130%]
            text-[12px]
            text-[#8a8f9e]
            border-t
            border-t-[#e3e5eb]
        "
    >
        <AppIconArrowDown size="16"/>

        <AppIconArrowUp size="16"/>

        <div class="m-[0_24px_0_8px]">
            <slot name="move-text">
                {{ $t('move-through-list') }}
            </slot>
        </div>

        <div class="mr-2 font-medium">
            Enter
        </div>

        <div class="mr-6">
            {{ $t('select') }}
        </div>

        <div class="mr-2 font-medium">
            Esc
        </div>

        <div>
            {{ $t('cancel') }}
        </div>

        <slot name="after-cancel"/>
    </div>
</template>
