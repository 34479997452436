<template>
    <AppIcon v-bind="$props">
        <template #default="{ color }">
            <path
                d="M13 20H11V8.00003L5.49996 13.5L4.07996 12.08L12 4.16003L19.92 12.08L18.5 13.5L13 8.00003V20Z"
                :fill="color"
            />
        </template>
    </AppIcon>
</template>
