import type { KBItem } from '~/ts/types/knowledge-base'

export default (itemsFlattenTree: KBItem[], item: KBItem): KBItem[] => {
    const ancestors = []

    const handler = (currentItem) => {
        if (!currentItem.parent_id) {
            return
        }

        const parentItem = itemsFlattenTree.find(v => v._id === currentItem.parent_id)

        if (!parentItem) {
            return
        }

        ancestors.unshift(parentItem)

        handler(parentItem)
    }

    handler(item)

    return ancestors
}
